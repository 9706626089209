.cardDetails {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 30px;
  border-radius: 8px;
  padding: 16px 24px;
  height: 182px;
  position: relative;
}

.xyz {
  display: flex;
  transition: transform 0.2s;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border-left: "1px solid white";
}
.xyz:hover {
  transform: scale(1.5);
}

.adjustment {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  line-height: 1.6;
}

.nameDesign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.statusDesign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  z-index: 2;
  line-height: 1.6;
}

.borderWhite {
  width: 80px;
  border-right: 1px solid white;
}

.valueDesign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  z-index: 2;
}

@media screen and (max-width: 1400px) {
  .valueDesign {
    font-size: 25px;
  }
  .statusDesign {
    font-size: 15px;
  }
  .cardDetails {
    gap: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .valueDesign {
    font-size: 20px;
  }
  .statusDesign {
    font-size: 12px;
  }
  .cardDetails {
    gap: 15px;
  }
  .borderWhite {
    width: 70px;
  }
}

@media screen and (max-width: 991px) {
  .valueDesign {
    font-size: 25px;
  }
  .statusDesign {
    font-size: 18px;
  }
  .cardDetails {
    gap: 30px;
  }
  .borderWhite {
    width: 80px;
  }
}
