:root {
  --info: #e91d25;
  --text: rgba(77, 71, 71, 1);
  --infoFocus: #ecbec0;
}

body {
  background: linear-gradient(
    14.47deg,
    rgba(255, 166, 166, 0.5) 1.59%,
    rgba(255, 230, 230, 0.5) 46.59%,
    rgba(206, 219, 255, 0.5) 90.65%
  ) !important;
  min-height: 100vh;
  overflow-y: auto;
}
/* Global Serach Date Picker css */
input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: #5f5f5f;
}

#search:focus-within {
  border: 2px solid var(--info);
}

/* Report css */

.createDateRangePicker.ant-calendar-picker-input.ant-input:hover {
  border-color: var(--info) !important;
  border: 2px solid var(--info) !important;
  outline: 0px !important;
  -webkit-box-shadow: 0 0 0 0px rgba(49, 139, 54, 0.342) !important;
  box-shadow: 0 0 0 0px rgba(49, 139, 54, 0.342) !important;
  border-radius: 0px !important;
  width: 100%;
  height: 40px;
}

.createDateRangePicker:hover {
  border-color: #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
}

.createDateRangePicker.ant-picker-focused {
  border-color: var(--info) !important;
  border: 2px solid var(--info) !important;
  outline: 0px !important;
  -webkit-box-shadow: 0 0 0 0px rgba(49, 139, 54, 0.342) !important;
  box-shadow: 0 0 0 0px rgba(49, 139, 54, 0.342) !important;
  border-radius: 0px !important;
}

.createDateRangePicker .ant-picker-active-bar {
  background-color: var(--info) !important;
}

.createDateRangePicker {
  border-radius: 0px !important;
}
.createDateRangePicker {
  width: 100%;
  height: 38px;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--infoFocus) !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--info) !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: var(--infoFocus) !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid var(--info) !important;
}

/* Dashboard css */

.daterangepicker.opensright:after {
  display: none !important;
}

.daterangepicker.opensright::before {
  display: none !important;
}

.daterangepicker .ranges li.active {
  background-color: var(--info) !important;
  color: #fff;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--info) !important;
  border-color: transparent;
  color: #fff;
}

.daterangepicker.opensright {
  z-index: 2 !important;
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  text-align: center;
  height: 42px;
  width: auto;
  border-radius: 2px 2px 0px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

@media screen and (max-width: 500px) {
  .tab {
    font-size: 14px;
  }
}

/* reconciliation-css */

.completed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1px 8px;
  width: 90px;
  background: #ebfbe6;
  border: 1px solid #a9ec91;
  color: #38d200;
  border-radius: 2px;
}

.failed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1px 8px;
  width: 90px;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
  color: #f5222d;
}

.pending {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1px 8px;
  width: 90px;
  background: #fff7e6;
  border: 1px solid #ffd591;
  border-radius: 2px;
  color: #fa8c16;
}

.inprogress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1px 8px;
  width: 90px;
  background: #e6f7fd;
  border: 1px solid #91dcf8;
  border-radius: 2px;
  color: #00adef;
}

.draft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1px 8px;
  width: 90px;
  background: #8f8e8e80;
  border: 1px solid #63636365;
  border-radius: 2px;
  color: #ffff;
}

.reconciliation-status-box {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 18px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
}

.reconciliation-match {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 16px;
  height: 40px;
  border: 1px solid #7cb305;
  border-radius: 4px;
  font-size: 15px;
}

.reconciliation-unmatch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 16px;
  height: 40px;
  border: 1px solid #cf1322;
  border-radius: 4px;
  font-size: 15px;
}

.rowStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  justify-content: center;
  gap: 3px;
  width: 100px;
  height: 24px;
  background: #f4ffb8;
  border: 1px solid #7cb305;
  border-radius: 2px;
  color: #5b8c00;
}

a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-7lij0j-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 32px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #aaa;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
